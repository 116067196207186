import { useFormik } from "formik";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Col, Container, Row, Button, Form, Table } from "react-bootstrap";
import * as yup from "yup";
import SelectInput from "../../components/UI/Formik/SelectInput/SelectInput";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import { AppContext } from "../../context/AppContext";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import SelectFileInputGeneral from "../../components/UI/SelectFileInput/SelectFileInputGeneral";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaInput from "../../components/UI/Formik/TextareaInput/TextareaInput";
import SelectInputCached from "../../components/UI/Formik/SelectInput/SelectInput.cached";
import { useSelector } from "react-redux";
import appConfig from "../../config/app.config";
import { GrDownload } from "react-icons/gr";

const validationSchema = yup.object().shape({
  TituloResposta: yup.string().required("Informe o titulo para sua impugnação."),
  emailrelator: yup.string().required("Informe o titulo para sua impugnação."),
  DescricaoResposta: yup.string().max(500, 'A descrição deve ter no máximo 500 caracteres.').required("Informe uma descrição resumida da sua impugnação."),
});

let initialValues = {
  idRelatorio: 0,
  IdAndamentoRelatorio: 0,
  DescricaoResposta: "",
  TituloResposta: "",
  emailrelator: "",
  Arquivo: null,
  ArquivoNome: null,
};


const DarAndamentoRelatorio = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading, requestId } = useApiClient();
  const [IdCategoria, setIdCategoria] = useState(0);
  const [isDisabled, setisDisabled] = useState(true);
  const auth = useSelector((state) => state.auth);
  const { idrelatorio } = useParams();
  initialValues.idRelatorio = idrelatorio ? idrelatorio : "";
  const history = useHistory();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });


  useEffect(() => {
    if (idrelatorio) {
      request(
        apiRoutes.main.relatorio.listarRelatorioPorId,
        { routeParams: { idrelatorio } },
        { requestId: "carregar" });
    }
  }, [request, idrelatorio]);



  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.relatorio.relatorioDarAndamento, values, {
        requestId: "andamento",
      });
    },
  });

  const { setValues } = formik;

  useEffect(() => {
    if (data && requestId === "carregar") {
      setValues(data)
      setIdCategoria(data.idcategoria)

    }

  }, [requestId, data]);

  const handleDownloadFile = (IdRelatorio, NomeArquivo) => {
    fetch(`${appConfig.host}/api/relatorio/baixar/arquivo/${IdRelatorio}`)
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = NomeArquivo;
        a.click();
      });
  };
  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data && !errors && requestId === "andamento") {
      toast.success('Relátorio respondido com Sucesso!!! enviaremos a resposta via Email.', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          history.push("/minha/area/su");
        }
      });
    }

    if (errors) {
      
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
      });
    }

  }, [data, errors, history]);


  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      Arquivo: uploadPayload.arquivo,
      ArquivoNome: uploadPayload.arquivoNome,
    }));
  };


  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">Relátorio</h1>
          </Col>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Form>
          <Row className="d-flex g-4 mt-5">
            <h4 className="my-3 mt-5">Detalhes do Relator</h4>
            <Col md="12" sm="12" xs="12">
              <TextInput formik={formik} field="nomerelator" label="Nome do Relator:" disabled={isDisabled} />
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <TextInput formik={formik} field="emailrelator" label="Email do Relator:" disabled={isDisabled} />
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <TextInput formik={formik} field="secretariarelator" label="Secretaria do Relator:" disabled={isDisabled} />
            </Col>

            { data && data.setorrelator &&(
            <Col lg="12" md="12" sm="12" xs="12">
              <TextInput formik={formik} field="setorrelator" label="Setor do Relator:" disabled={isDisabled} />
            </Col>)}

            <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
            </Col>
          </Row>
          <Row className="d-flex g-4 mt-3">
            <h4 className="my-3 mt-3">Detalhes do Relatório</h4>
            <Col md="12" sm="12" xs="12">
              <TextInput formik={formik} field="titulo" label="Titulo do relatório:" disabled={isDisabled} />
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <SelectInputCached
                formik={formik}
                field="IdCategoria"
                label="Categoria do relatório"
                cacheKey="categoria"
                disabled={isDisabled}
                id={IdCategoria || ""}
              />
            </Col>

            <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
              <TextareaInput
                formik={formik}
                field="descricao"
                label="Descrição do relatório."
                rows={5}
                disabled={isDisabled}
              />
            </Col>
          </Row>

          {data && data.nm_arquivo && data.idrelatorio && (
            <Row>
              <Col className="mt-4" lg="12" md="12" sm="12" xs="12">
                <h3 className="my-3">Fudamentação do relatório</h3>
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <div>
                  <p>
                    Nesta seção, você tem a opção de baixar o arquivo que foi utilizado para fundamentar o relatório.
                  </p>
                </div>
              </Col>
              <Row >
                <Col className="mt-3" lg="12" md="12" sm="12" xs="12">
                  <div style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover>

                      <thead>
                        <tr >
                          <th>Arquivo</th>
                          <th className="text-center align-middle">Baixar</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr key={data.idrelatorio}>
                          <td>
                            {data.nm_arquivo}
                          </td>
                          <td className="text-center align-middle">
                            <GrDownload
                              size={20}
                              color="orange"
                              onClick={() => handleDownloadFile(data.idrelatorio, data.nm_arquivo)}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Row>)}

          <Row>
            <Row className="mt-5">
              <Col lg="12" md="12" sm="12" xs="12">
                <h2 className="my-3">Resposta do relatório</h2>
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <p>Após revisar os dados do relatório, você estará apto a dar prosseguimento ao relatório. Lembre-se que sua resposta será enviada via e-mail.</p>
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12" xs="12">
                <TextInput formik={formik} field="TituloResposta" label="Titulo da resposta:" placeholder="Adicione um titulo" disabled={isSubmitting} />
              </Col>
              <Col className="mt-3" lg="12" md="12" sm="12" xs="12">
                <SelectInputCached
                  formik={formik}
                  field="IdAndamentoRelatorio"
                  label="Selecione o andamento"
                  defaultText="Selecione um andamento"
                  cacheKey="andamentosRelatorio"
                  isAndamentoRelatorio
                  disabled={isSubmitting}
                />
              </Col>
              <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                <TextareaInput
                  formik={formik}
                  field="DescricaoResposta"
                  label="Informe uma descrição para sua resposta:"
                  placeholder="Descreva aqui."
                  rows={4}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg="12" md="12" sm="12" xs="12">
                <h4 className="my-3">Seleção de arquivo(opcional)</h4>
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <p>Você pode anexar à sua resposta um arquivo para fudamentação.</p>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2" lg="12" md="6" sm="6" xs="12">
                <SelectFileInputGeneral
                  ArquivoRelatorio
                  disabled={loading}
                  show={!readOnly}
                  showListfile={!readOnly}
                  field="arquivonovo"
                  handleArquivo={handleArquivo}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-5">
              <Button
                className="mt-3"
                disabled={readOnly}
                onClick={() => formik.handleSubmit()}
              >
                <SpinnerLabel
                  loading={formik.isSubmitting}
                  label="Salvar"
                />
              </Button>
              &nbsp;&nbsp;
              <Button
                className="mt-3"
                onClick={() => history.goBack()}
                disabled={formik.isSubmitting}
              >
                Voltar
              </Button>
            </Col>
          </Row>
        </ Form>
      </Container>
    </>
  )
}

export default DarAndamentoRelatorio;
