import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Alert, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient.js";
import TabelPagination from "../../../components/pagination/TabelPaginatio.js";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached.js";
import { FormatDate } from "../../../components/UI/Format/Format.js";
import { useHistory } from "react-router";
import { MdOutlineSearch } from "react-icons/md";



const initialValues = {
  IdCategoria: 0,
};


const SalvarPesquisa = {
  IdCategoria: 0,
};


const ListagemDeRelatorios = () => {
  const { request, data, errors, requestId } = useApiClient();
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(true);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const itemsPerPage = 5;



  useEffect(() => {
    request(apiRoutes.main.relatorio.listarRelatorios,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request, offset]);


  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.relatorio.pesquisar,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            idcategoria: SalvarPesquisa.IdCategoria,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);


  const FiltrarPesquisa = (values) => {
    SalvarPesquisa.IdCategoria = values.IdCategoria;
    setlimparPesquisa(false);
    request(apiRoutes.main.relatorio.pesquisar,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          idcategoria: SalvarPesquisa.IdCategoria,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true)
    formik.setValues({
      IdCategoria: 0,
    });
    SalvarPesquisa.IdCategoria = 0;
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.relatorio.listarRelatorios,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: 0
        }
      },
      { requestId: "carregar" }
    );
  };

  useEffect(() => {

    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }

    if (errors) {
      toast.error(errors.message || "Desculpe, algo deu errado. Tente novamente mais tarde.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }
  }, [data, requestId])

  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };


  return (
    <>
      <Container className="conteudo-pagina mt-5 mb-5">
        <Row>
          <Col>
            <h3 className="mt-4 fw-bold">Relatórios Recebidos</h3>
          </Col>
        </Row>


        <Row>
          <Col>
            <p>
            Nesta seção, todos os relatórios estão listados. Para visualizar o relatório completo, clique no item desejado, e o sistema o redirecionará automaticamente para a página de andamento correspondente.
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="7" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="IdCategoria"
                    label="Pesquisar por categoria do relatório"
                    cacheKey="categoria"
                    categoriaRelatorio
                    allowEmpty
                  />
                </Col>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                    <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                        Pesquisar
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                    <Button variant="link" className="ms-3" onClick={limparCampos}>
                      Limpar
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            {showSpinner && (
              <Row>
                <Col className="d-flex flex-row mt-5 justify-content-center">
                  <h5 className="mb-2">
                    <Spinner
                      animation="border"
                      size="lg"
                      variant="warning"
                    />
                  </h5>
                </Col>
              </Row>
            )}

            {!showSpinner && data && data.length === 0 && (
              <Alert className="mt-3" variant="primary">Nenhum registro encontrado</Alert>
            )}
            {paginatedData && !showSpinner && paginatedData.length > 0 && (
              <>
                <div style={{ overflowX: 'auto' }}>
                  <Table striped bordered hover className="mt-4">
                    <thead>

                      <tr>
                        <th>Titulo</th>
                        <th>Categoria do relatório</th>
                        <th>Secretaria</th>
                        <th>Setor</th>
                        <th>Data de inclusão</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData && Array.isArray(paginatedData) && paginatedData.map((itemData) => (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={        
                              <Tooltip>
                                <span>
                                  <MdOutlineSearch  size={17} style={{ marginRight: '3px' }} />
                                   Detalhar relatório
                                 </span>
                              </Tooltip>}>
                        <tr key={itemData.idrelatorio} style={{ cursor: "pointer" }} onClick={() => history.push(`/usuario/andamento/relatorio/${itemData.idrelatorio}`)}>
                          <td>{itemData.tx_titulo}</td>
                          <td>{itemData.categoria}</td>
                          <td>{itemData.tx_orgao}</td>
                          <td>{FormatDate(itemData.tm_inclusao)}</td>
                          <td>{itemData.status}</td>
                        </tr>
                      </OverlayTrigger>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {data && totalPages > itemsPerPage && (
                  <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />)}
              </>
            )}

          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListagemDeRelatorios;
