import React, { useContext, useEffect } from "react";
import { Col, Container, Row, Table, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { GrDownload } from "react-icons/gr";
import appConfig from "../../config/app.config";
import { FormatDate } from "../../components/UI/Format/Format";




const ConteudoListagemArquivos = ({ section1, section2, data, dataAndamento, tipo }) => {
  const { setBackgroundClassList } = useContext(AppContext);

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const handleDownloadFile = (IdAquivo, NomeArquivo) => {
    if (tipo === "chamamento") {
      fetch(`${appConfig.host}/api/chamamentopublico/baixar/${IdAquivo}`)
        .then((res) => res.blob())
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = NomeArquivo;
          a.click();
        });
    }
    if (tipo === "termo") {
      fetch(`${appConfig.host}/api/termosacordos/baixar/termo/${IdAquivo}`)
        .then((res) => res.blob())
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = NomeArquivo;
          a.click();
        });
    }

    if (tipo === "MIS") {
      fetch(`${appConfig.host}/api/proposta/arquivo/download/${IdAquivo}`)
        .then((res) => res.blob())
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = NomeArquivo;
          a.click();
        });
    }
  }

  return (
    <>
      <Container id="conteudo-pagina-interna">
        <Row className="d-flex g-4">
          <Col md="12" sm="12" xs="12">
            <h2 className="my-3 mt-5">{section1.titulo}</h2>
          </Col>
          <Col className="mt-1" lg="12" md="12" sm="12" xs="12">
            <p>
              {section1.subtitulo}
            </p>
          </Col>
          <Col className="mt-1" lg="12" md="12" sm="12" xs="12">
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr >
                    <th>Andamento</th>
                    <th>Data do andamento</th>
                  </tr>
                </thead>
                <tbody>
                  {dataAndamento && dataAndamento.map((itemData) => (
                    <tr key={itemData.idandamento}>
                      <td>{itemData.nomeandamento}</td>
                      <td>{FormatDate(itemData.adamentoinclusao)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          {dataAndamento.length === 0 && (
            <Alert className="mt-1" variant="primary">Nenhum registro encontrado</Alert>
          )}
        </Row>
        <Row className="d-flex g-4 mt-5">
          <Col md="12" sm="12" xs="12">
            <h2 className="my-3 mt-5">{section2.titulo}</h2>
          </Col>
          <Col className="mt-1" lg="12" md="12" sm="12" xs="12">
            <p>
              {section2.subtitulo}
            </p>
          </Col>
          <Col className="mt-1" lg="12" md="12" sm="12" xs="12">
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr >
                    <th>Arquivo</th>
                    <th>Data de inclusão</th>
                    <th className="text-center align-middle">Baixar</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map((itemData) => (
                    <tr key={itemData.idarquivo}>
                      <td>{itemData.nomearquivo}</td>
                      <td>{FormatDate(itemData.inclusao)}</td>
                      <td className="text-center align-middle">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip className="medium mt-1">Baixar arquivo</Tooltip>}>
                          <GrDownload
                            size={20}
                            color="orange"
                            onClick={() => handleDownloadFile(itemData.idarquivo, itemData.nomearquivo)}
                            style={{ cursor: "pointer" }}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          {data.length === 0 && (
            <Alert className="mt-1" variant="primary">Nenhum registro encontrado</Alert>
          )}
        </Row>
      </Container>

    </>
  );
};

export default ConteudoListagemArquivos;
