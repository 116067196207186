import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as yup from "yup";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import { AppContext } from "../../context/AppContext";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import SelectFileInputGeneral from "../../components/UI/SelectFileInput/SelectFileInputGeneral";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaInput from "../../components/UI/Formik/TextareaInput/TextareaInput";


const validationSchema = yup.object().shape({
  Titulo: yup.string().required("Informe o titulo para sua impugnação."),
  Descricao: yup.string().max(500, 'A descrição deve ter no máximo 500 caracteres.').required("Informe uma descrição resumida da sua impugnação."),

});

const initialValues = {
  Titulo: "",
  Descricao: "",
  Arquivo: "",
  ArquivoNome: "",
  IdImpugnacao: 0,
};

const ResponderImpugnacao = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading, requestId } = useApiClient();
  const history = useHistory();
  const { id } = useParams();
  initialValues.IdImpugnacao = id;


  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.impugnacao.responderImpugnacao, values, {
        requestId: "salvar",
      });
    },
  });

  useEffect(() => {
    if (data && requestId === "carregar") {
      formik.setValues({
        Email: data.tx_email
      });
    }
  }, [data, requestId])




  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      Arquivo: uploadPayload.arquivo,
      ArquivoNome: uploadPayload.arquivoNome,
      IdImpugnacao: id,
    }));
  };

  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data && requestId === "salvar") {
      setSubmitting(false);
      toast.success('Impugnação Respondida com sucesso!!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          history.push("/listar/impugnacoes");
        }
      });
    }

    if (errors) {
      setSubmitting(false);
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload();
        }
      });
    }

  }, [data, errors, history]);

  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5"> Responder Impugnação</h1>
          </Col>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        
        <Form>
        <Row>
              <div className="alert alert-info mt-5" role="alert">
                    <h4 className="alert-heading">Instruções</h4>
                    <p>A impugnação de um chamamento público é o ato de contestar formalmente os termos ou condições estabelecidos em um edital de seleção ou contratação pública. Ela ocorre quando um interessado, ou potencial participante, identifica irregularidades, ilegalidades ou falta de clareza no edital, solicitando sua revisão ou cancelamento. A impugnação visa garantir a transparência e a legalidade do processo, assegurando igualdade de condições para todos os participantes.</p>
                    <p> De acordo com a legislação vigente, a gestão dispõe de até 5 dias para responder a uma impugnação relacionada a um chamamento público, contados a partir da data de publicação do referido chamamento.</p>
                    <p> Lembre-se de que a resposta será encaminhada para o e-mail do usuário que apresentou a impugnação.</p>
              </div>
            </Row>

          <Row className="d-flex g-4 mt-5">
            <Col className="mt-5" md="12" sm="12" xs="12">
              <h2 className="my-3">Dados da resposta à impugnação</h2>
            </Col>

            <Col lg="12" md="12" sm="12" xs="12">
              <TextInput formik={formik} field="Titulo" label="Titulo:" placeholder="Adicione um titulo à resposta da impugnação" disabled={isSubmitting} />
            </Col>

            <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
              <TextareaInput
                formik={formik}
                field="Descricao"
                label="Descrição da resposta"
                placeholder="Descreva brevimente a sua resposta"
                disabled={isSubmitting}
                rows={4}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <h3 className="my-2">Fudamentação da sua resposta</h3>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <p>
                Nesta seção, você tem a opção de anexar um arquivo PDF para fundamentar sua resposta. Para anexar um arquivo clique em "Selecionar Arquivo".
              </p>
            </Col>
            <Col className="mt-2" lg="12" md="6" sm="6" xs="12">
              <SelectFileInputGeneral
                disabled={loading}
                show={!readOnly}
                showListfile={!readOnly}
                field="arquivonovo"
                handleArquivo={handleArquivo}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-5">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">{"Responder Impugnação"}</Tooltip>}
              >
                <Button
                  className="mt-3"
                  disabled={isSubmitting}
                  onClick={() => formik.handleSubmit()}
                >
                  <SpinnerLabel
                    loading={isSubmitting}
                    label="Enviar Resposta"
                  />
                </Button>
              </OverlayTrigger>
              &nbsp;&nbsp;
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                <Button
                  className="mt-3"
                  onClick={() => history.goBack()}
                  disabled={isSubmitting}
                >
                  Voltar
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </ Form>
      </Container>
    </>
  )
}

export default ResponderImpugnacao;
