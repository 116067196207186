import {Nav, Navbar, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import {AiFillHome} from "react-icons/ai";
import {FaUser} from "react-icons/fa";
import {LinkContainer} from "react-router-bootstrap";
import ImgMarca from "../assets/img/marca-brasao-rn.png";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const TopMenu = () => {
  const papeis = useSelector((state) => state.auth);
  //let logadoAuthenticated;
  // try{
  //   logadoAuthenticated = JSON.parse(sessionStorage.getItem("user")).isAuthenticated
  // }catch{

  //   logadoAuthenticated = null;
  // }
  // const isAuthenticated = useSelector((state) => logadoAuthenticated==null ?  state.auth.isAuthenticated: JSON.parse(sessionStorage.getItem("user")).isAuthenticated);
  // const papeis = useSelector((state) =>  logadoAuthenticated==null ? state.auth:JSON.parse(sessionStorage.getItem("user")).user.papeis[0]);
  const [isServidor, setIsServidor] = useState(false);
  const [isProponente, setIsProponente] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCoordenador, setIsCoordenado] = useState(false);
  const [isSU, setIsSU] = useState(false);

  useEffect(() => {
    if (papeis.isAuthenticated === true) {
      if (papeis.user.papeis[0] === "SERVIDOR") {
        setIsServidor(true);
      } else if (papeis.user.papeis[0] === "PROPONENTE") {
        setIsProponente(true);
      } else if (papeis.user.papeis[0] === "COORDENADOR") {
        setIsCoordenado(true);
      } else if (papeis.user.papeis[0] === "ADMIN") {
        setIsAdmin(true);
      } else if (papeis.user.papeis[0] === "SU") {
        setIsSU(true);
      }
    } else {
      setIsServidor(false);
      setIsProponente(false);
      setIsAdmin(false);
      setIsCoordenado(false);
      setIsSU(false);
    }
  }, [papeis, papeis.isAuthenticated]);

  return (
    <>
      <Navbar variant="dark" expand="lg" collapseOnSelect>
      <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
            />
        <LinkContainer to="/">
          <Navbar.Brand>
            <>
              <div className="navbar-logo">
                <img src={ImgMarca} alt="OSC RN" /> Portal OSC RN
              </div>
            </>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-menu" />

        <Navbar.Collapse id="navbar-menu" className="justify-content-end">
          <Nav id="navbar-menu-nav">
          <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Ir para páginal inicial</Tooltip>}>
                  <LinkContainer to="/" exact>
                    <Nav.Link className="text-shadow">
                      <AiFillHome /> <span>Início</span>
                    </Nav.Link>
                  </LinkContainer>
            </OverlayTrigger>

            <LinkContainer to="/legislacao">
              <Nav.Link className="text-shadow">
                {" "}
                <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Acessar legislação do portal OSC</Tooltip>}>
                <span>Legislação</span>
                </OverlayTrigger>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/parcerias/termoseacordos">
              <Nav.Link className="text-shadow">
                {" "}
                <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Acessar termos e acordos publicados no portal OSC</Tooltip>}>
                <span>Termos e Acordos</span>
                </OverlayTrigger>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/publicacoes">
              <NavDropdown
                title={
                  <>
                    {" "}
                    <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Chamamentos Públicos e MIS publicados no Portal OSC</Tooltip>}>
                    <span className="text-shadow">Publicações</span>
                    </OverlayTrigger>
                  </>
                }
              >
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Acessar chamamentos públicos publicados no portal OSC</Tooltip>}>
                      <LinkContainer to="/publicacoes/chamamentos">
                        <NavDropdown.Item>Chamamentos Públicos</NavDropdown.Item>
                      </LinkContainer>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Acessar MIS publicadas no portal OSC</Tooltip>}>
                      <LinkContainer to="/publicacoes/interessesocial">
                        <NavDropdown.Item>
                          Manifestações de Interesse Sociais
                        </NavDropdown.Item>
                      </LinkContainer>
                </OverlayTrigger>
              </NavDropdown>
            </LinkContainer>

            <LinkContainer to="/perguntas/frequentes">
              <Nav.Link className="text-shadow">
                {" "}
                <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Perguntas frequentes sobre o portal OSC</Tooltip>}>
                <span>Perguntas Frequentes</span>
                </OverlayTrigger>
              </Nav.Link>
            </LinkContainer>

            {/** ---- TODO: TRANSFORMAR EM UM COMPONENTE PARA EVITAR REPETIÇÃO ---- */}
            {isServidor && (
             <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Acessar área de perfil</Tooltip>}>
                  <LinkContainer to="/minha/area/servidor">
                    <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                      <FaUser /> <span>Minha Área</span>
                    </Nav.Link>
                  </LinkContainer>
              </OverlayTrigger>
            )}

            {isAdmin && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Acessar área de perfil</Tooltip>}>
                    <LinkContainer to="/minha/area/admin">
                      <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                        <FaUser /> <span>Minha Área</span>
                      </Nav.Link>
                    </LinkContainer>
              </OverlayTrigger>
            )}

            {isProponente && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Acessar área de perfil</Tooltip>}>
                    <LinkContainer to="/minha-area">
                      <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                        <FaUser /> <span>Minha Área</span>
                      </Nav.Link>
                    </LinkContainer>
              </OverlayTrigger>
            )}

            {isCoordenador && (
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Acessar área de perfil</Tooltip>}>
                  <LinkContainer to="/minha/area/coordenador">
                    <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                      <FaUser /> <span>Minha Área</span>
                    </Nav.Link>
                  </LinkContainer>
              </OverlayTrigger>
            )}

            {isSU && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Acessar área de perfil</Tooltip>}>
                  <LinkContainer to="/minha/area/su">
                    <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                      <FaUser /> <span>Minha Área</span>
                    </Nav.Link>
                  </LinkContainer>
              </OverlayTrigger>
            )}

            {!papeis.isAuthenticated && (
               <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Entrar no portal OSC</Tooltip>}>
                    <LinkContainer to="/login">
                      <Nav.Link className=" bg-primary entrar text-white rounded-pill opacity-1 px-3">
                        <FaUser /> <span>Entrar</span>
                      </Nav.Link>
                    </LinkContainer>
              </OverlayTrigger>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default TopMenu;
