import React, { useContext, useEffect } from "react";
import { Col, Container, Row, OverlayTrigger, Tooltip} from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { IoMdLogOut } from "react-icons/io";
import { FaUserPlus } from "react-icons/fa";
import ImgDemoFotoPerfil from "../../../assets/img/foto-perfil-placeholder.png";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import ListagemServidores from "../ListagemUsuarios/ListagemServidores";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../redux/auth";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { TbMessageReport } from "react-icons/tb";
import ListaPropostasRecebidas from "../../Proposta/ListaPropostasRecebidas/ListaPropostasRecebidas";
import ListaChamamentosPublicos from "../../Chamamento/ListaChamamentosPublicos/ListaChamamentosPublicos";
import { GiBugleCall } from "react-icons/gi";
import { AiOutlineSolution } from "react-icons/ai";
import { BiUpload } from "react-icons/bi";
import ListaTermosAcordos from "../../TermosEAcordos/ListaTermosAcordos/ListaTermosAcordos";
import { PiMonitorPlayBold } from "react-icons/pi";

const MinhaAreaCoordenador = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data } = useApiClient();
  var IdCoordenador = null;

  useEffect(() => {
    request(apiRoutes.main.UserGov.CarregarUserGovLogado, null, {
      requestId: "carregar",
    });
  }, [request]);

  if (data) {
    IdCoordenador = data.value.id;
  }

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  }, []);

  return (
    <>
      {auth.user && auth.user.papeis[0] === "COORDENADOR" ? (
        <>
          <Container id="cabecalho-pagina" fluid>
            <Row>
              <Col className="d-flex flex-column text-center mt-5">
                <h1 className="text-white text-shadow-orange mt-5">Minha Área</h1>
              </Col>
            </Row>
          </Container>
          <Container  id="conteudo-pagina-interna">
            <Row>
              <Col className="d-flex flex-column mt-5">
                <Row className="g-4 g-lg-4">
                  <Col md="6" className="me-auto ms-auto text-center">
                    <Link to="/minha-area/usuario">
                      <img
                        src={ImgDemoFotoPerfil}
                        alt="Foto do usuário"
                        className="img rounded-pill w-25 box-shadow"
                      />
                    </Link>
                    <h2>{auth.user.nome}</h2>
                    <p className="mt-2 fw-bolder title-uppercase text-gray">
                      {auth.user.email}
                    </p>
                  </Col>
                </Row>

                <Row className="g-4 g-lg-4">
                  <Col md="12" className="me-auto ms-auto text-center">
                    <ul id="profile-menu" className="mt-md-3 mt-lg-3">
                      <li>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Atualizar seus dados</Tooltip>}>
                          <Link
                            className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                            to="/update/user/gov"
                          >
                            <BsFillPersonFill size={20} />{" "}
                            <span className="d-md-inline">Seus dados</span>
                          </Link>

                        </OverlayTrigger>
                      </li>
                      <li>
                      <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Cadastrar novo servidor no sistema</Tooltip>}>
                        <Link
                          className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                          to="/novo/servidor"
                        >
                          <FaUserPlus size={20} />{" "}
                          <span className="d-md-inline">Adicionar servidor</span>
                        </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                      <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Lançar novo chamamento público no sistema</Tooltip>}>
                        <Link
                          className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                          to="/upload/chamamento"
                        >
                          <GiBugleCall size={20} />{" "}
                          <span className="d-md-inline">Chamamentos públicos</span>
                        </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                      <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Ver impugnações</Tooltip>}>
                      <Link
                        className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                        to="/listar/impugnacoes"
                      >
                        <AiOutlineSolution size={20} />{" "}
                        <span className="d-md-inline">Impugnações</span>
                      </Link>
                      </OverlayTrigger>
                      </li>
                      
                        <li>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Lançar termos e acordos</Tooltip>}>
                          <Link
                            className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                            to="/upload/termos-acordos"
                          >
                            <BiUpload size={20} />{" "}
                            <span className="d-md-inline">Termos e Acordos</span>
                          </Link>
                          </OverlayTrigger>
                        </li>

                          <li>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium mt-1">Reportar erro no sistema</Tooltip>}>
                            <Link
                              className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                              to="/usuario/reportar"
                            >
                              <TbMessageReport size={20} />{" "}
                              <span className="d-md-inline">Reportar</span>
                            </Link>
                            </OverlayTrigger>
                        </li>

                        <li>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip className="medium mt-1">Acessar videos tutoriais de utilização do portal OSC</Tooltip>}
                          >
                            <a
                              className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                              target="_blank"
                              href="https://www.youtube.com/playlist?list=PLmLBTr3AFPkoE2pxULwKrsDI_N7FOF4tv"
                              rel="noopener noreferrer"
                            >
                              <PiMonitorPlayBold size={20} />{" "}
                              <span className="d-md-inline">Tutoriais</span>
                            </a>
                          </OverlayTrigger>
                        </li>

                        <li>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Sair do sistema</Tooltip>}>
                        <a
                          className="btn btn-outline  btn-outline-danger  btn-sm rounded-pill"
                          href="/"
                          onClick={() => dispatch(authActions.signout())}
                        >
                          <IoMdLogOut /> Sair
                        </a>

                        </OverlayTrigger>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
            <ListagemServidores />
            <ListaPropostasRecebidas />
            <ListaChamamentosPublicos />
            <ListaTermosAcordos />

          </Container>
        </>
      ) : (
        <p>Carregando...</p>
      )}
    </>
  );
};

export default MinhaAreaCoordenador;
