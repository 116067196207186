import React, { useEffect, useCallback, useState } from "react";
import { Col, Container, Row, Table, Spinner ,OverlayTrigger, Tooltip } from "react-bootstrap";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import SelectFileInput from "../../../components/UI/SelectFileInput/SelectFileInput";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiDeleteBin2Fill } from "react-icons/ri";
import appConfig from "../../../config/app.config";
import TabelPagination from "../../../components/pagination/TabelPaginatio";
import { useParams } from "react-router";
import { GrDownload } from "react-icons/gr";



const InformacaoSecao = {
  titulo: "default",
  subtitulo: "default",
};

const ListarArquivosChamamento = () => {
  const { request, data, errors, loading, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const itemsPerPage = 6;
  const { id, tipo } = useParams();




  useEffect(() => {
    if (tipo === "chamamento") {
      request(apiRoutes.main.chamamentoPublico.listarArquivosPorChamamento,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offset,
            id: id,
          }
        },
        { requestId: "carregar" }
      );
    }
    if (tipo === "termo") {
      request(apiRoutes.main.termosAcordos.listarArquivosTermosAcordos,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offset,
            id: id,
          }
        },
        { requestId: "carregar" }
      );
    }
  }, [requestId, id, request, tipo, offset]);



  const requestListaArquivos = useCallback((requestIdSelect) => {
    if (id && requestIdSelect === "salvar-arquivo") {
      toast.success('Arquivo salvo com Sucesso!!!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          if (tipo === "chamamento") {
            request(apiRoutes.main.chamamentoPublico.listarArquivosPorChamamento,
              {
                routeParams: {
                  limit: itemsPerPage,
                  offset: 0,
                  id: id,
                }
              },
              { requestId: "carregar" }
            );
          }
          if (tipo === "termo") {
            request(apiRoutes.main.termosAcordos.listarArquivosTermosAcordos,
              {
                routeParams: {
                  limit: itemsPerPage,
                  offset: offset,
                  id: id,
                }
              },
              { requestId: "carregar" }
            );
          }

          setShowSpinner(false);
        }
      });
    }
  }, [request, id]);



  const activateSpinner = useCallback(() => {
    setShowSpinner(true)
  }, [setShowSpinner]);



  useEffect(() => {
    if (data && requestId === "Remover") {
      toast.success('Arquivo Removido com Sucesso!!! Insira outro arquivo clicando no botão "Selecionar Arquivo".', {
        position: "top-center",
        autoClose: 900,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          
          setoffset(0)
        }
      });
    }


    if (data && requestId === "carregar") {
      if (tipo === "chamamento") {
        InformacaoSecao.titulo = "Arquivos do chamamento";
        InformacaoSecao.subtitulo = "Nesta seção, você pode anexar arquivos PDF ao chamamento público. Para vincular arquivos ao seu chamamento, basta clicar em 'Selecionar Arquivo' e escolher o arquivo desejado. O arquivo será automaticamente associado ao seu chamamento. Lembre-se de manter sempre o histórico de arquivos no sistema, a fim de promover maior transparência para com o cidadão.";
      } else if (tipo === "termo") {
        InformacaoSecao.titulo = "Arquivos do termo ou acordo";
        InformacaoSecao.subtitulo = "Nesta seção, você pode anexar arquivos PDF ao termo ou acordo. Para vincular arquivos ao seu termo ou acordo, basta clicar em 'Selecionar Arquivo' e escolher o arquivo desejado. O arquivo será automaticamente associado ao seu termo ou acordo. Lembre-se de manter sempre o histórico de arquivos no sistema, a fim de promover maior transparência para com o cidadão.";
      }
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: {
          width: "500px",
        },
        onClose: () => {
          window.location.reload();
        }
      });

    }
  }, [errors, data, requestId]);

  const handleRemoveFile = (id) => {
    setShowSpinner(true)
    if (id) {
      if (tipo === "chamamento") {
        request(
          apiRoutes.main.chamamentoPublico.removerArquivoChamamento,
          { routeParams: { id } },
          { requestId: "Remover" }
        );
      }
      if (tipo === "termo") {
        request(
          apiRoutes.main.termosAcordos.removerArquivoTermoAcordo,
          { routeParams: { id } },
          { requestId: "Remover" }
        );
      }
    }

  }

  const handleDownloadFile = (IdArquivo, NomeArquivo) => {
    if (tipo === "chamamento") {
      fetch(`${appConfig.host}/api/chamamentopublico/baixar/${IdArquivo}`)
        .then((res) => res.blob())
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = NomeArquivo;
          a.click();
        });
    }
    if (tipo === "termo") {
      fetch(`${appConfig.host}/api/termosacordos/baixar/termo/${IdArquivo}`)
        .then((res) => res.blob())
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = NomeArquivo;
          a.click();
        });
    }

  }


  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    setoffset(newOffset);

  };




  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Relacionar e remover Arquivos
            </h1>
          </Col>
        </Row>
      </Container>


      <Container id="conteudo-pagina-interna">
        <Row className="d-flex g-4">
          <Row>
            <Col md="12" sm="12" xs="12" className="mt-5">
              <h2 className="my-3">{InformacaoSecao.titulo}</h2>
            </Col>
          </Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <p className="my-3">
              {InformacaoSecao.subtitulo}
            </p>
          </Col>
          <Col className="mt-5" lg="6" md="6" sm="6" xs="12">
            <SelectFileInput
              show={true}
              tipo={tipo}
              defaultPayload={tipo === "chamamento" ? { IdChamamento: id } : { IdTermoAcordo: id }}
              onFinishUpload={requestListaArquivos}
              activateSpinner={activateSpinner}
            />
          </Col>
          {paginatedData && (
            <Col className="mt-5" lg="6" md="6" sm="6" xs="12">
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Arquivo</th>
                      <th>Remover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!showSpinner && paginatedData.map((itemData) => (

                      <tr key={itemData.idarquivo}>
                      <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                <span>
                                  <GrDownload size={17} style={{ marginRight: '3px' }} />
                                   Baixar arquivo
                                 </span>
                              </Tooltip>}>
                              <td style={{ cursor: "pointer" }} onClick={() => handleDownloadFile(itemData.idarquivo, itemData.nomearquivo)}>
                                  {itemData.nomearquivo}
                              </td>
                      </OverlayTrigger>
                      <td className="text-center">
                        <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium mt-1">Remover Arquivo</Tooltip>}>
                          <RiDeleteBin2Fill
                            size={20}
                            color="red"
                            onClick={() => handleRemoveFile(itemData.idarquivo)}
                            style={{ cursor: "pointer" }}
                          />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data && totalPages > 1 && !showSpinner && (
                  <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />
                )}
                {showSpinner && (
                  <div className="text-center">
                    <Spinner style={{ textAlign: 'center' }} animation="border" size="lg" variant="warning" />
                  </div>
                )}
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default ListarArquivosChamamento;
